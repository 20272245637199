<template>
  <div v-if="productMenu && productMenu.length > 0" class="index-model-section">
    <HomePageModels
      :product-menu="productMenu"
      @getProductInfo="getProductInfo"
      @handleLoad="handleLoad"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Car360 from '@/components/Car360.vue'
import HomePageModels from '@/components/Models.vue'

export default {
  name: 'HomeView',
  components: {
    Car360,
    HomePageModels
  },
  data() {
    return {
      productMenu: [],
      wheelList: [
        {
          id: 31,
          wheelLeft: require('../assets/image/home/wheel/BJ60-01.png'),
          wheelRight: require('../assets/image/home/wheel/BJ60-02.png'),
          width: '12.823%',
          bottom: '9.866%',
          left: '19.705%',
          right: '23.647%',
          rotateId: [0, 1, 2, 3, 4, 5]
        },
        {
          id: 20,
          wheelLeft: require('../assets/image/home/wheel/BJ40-PLUS-01.png'),
          wheelRight: require('../assets/image/home/wheel/BJ40-PLUS-02.png'),
          width: '13.058%',
          bottom: '9.333%',
          left: '18.647%',
          right: '22.705%',
          carWidth: '89%',
          position: {
            top: '57%',
            left: '48%'
          },
          rotateId: [0, 3, 4, 5, 6]
        },
        {
          id: 24,
          wheelLeft: require('../assets/image/home/wheel/BJ40-SE-01.png'),
          wheelRight: require('../assets/image/home/wheel/BJ40-SE-02.png'),
          width: '14.352%',
          bottom: '5.333%',
          left: '16.706%',
          right: '23.941%',
          rotateId: []
        },
        {
          id: 14,
          wheelLeft: require('../assets/image/home/wheel/BJ80-01.png'),
          wheelRight: require('../assets/image/home/wheel/BJ80-02.png'),
          width: '12.941%',
          bottom: '5.066%',
          left: '18.647%',
          right: '23.235%',
          rotateId: []
        },
        {
          id: 25,
          wheelLeft: require('../assets/image/home/wheel/F40-01.png'),
          wheelRight: require('../assets/image/home/wheel/F40-02.png'),
          width: '15.294%',
          bottom: '7.2%',
          left: '17.411%',
          right: '23.176%',
          rotateId: []
        },
        {
          id: 30,
          wheelLeft: require('../assets/image/home/wheel/new-X7-01.png'),
          wheelRight: require('../assets/image/home/wheel/new-X7-02.png'),
          width: '12.588%',
          bottom: '10.8%',
          left: '19.882%',
          right: '20.176%',
          rotateId: [0, 1, 2, 3, 4, 5, 6]
        },
        {
          id: 18,
          wheelLeft: require('../assets/image/home/wheel/x7-01.png'),
          wheelRight: require('../assets/image/home/wheel/x7-02.png'),
          width: '14.5%',
          bottom: '11%',
          left: '18.8%',
          right: '17.8%',
          rotateId: []
        },
        {
          id: 21,
          wheelLeft: require('../assets/image/home/wheel/X35-01.png'),
          wheelRight: require('../assets/image/home/wheel/X35-02.png'),
          width: '13.353%',
          bottom: '7.267%',
          left: '18.059%',
          right: '16.294%',
          rotateId: [0, 1, 2, 3]
        },
        {
          id: 26,
          wheelLeft: require('../assets/image/home/wheel/X55II-01.png'),
          wheelRight: require('../assets/image/home/wheel/X55II-02.png'),
          width: '13.941%',
          bottom: '9.733%',
          left: '17.882%',
          right: '17.352%',
          rotateId: [0, 1, 2, 3, 4, 5]
        },
        {
          id: 17,
          wheelLeft: require('../assets/image/home/wheel/u5-plus-01.png'),
          wheelRight: require('../assets/image/home/wheel/u5-plus-02.png'),
          width: '12.706%',
          bottom: '6.133%',
          left: '17.118%',
          right: '18.647%',
          rotateId: [0, 1, 2, 3]
        },
        {
          id: 8,
          wheelLeft: require('../assets/image/home/wheel/eu5-01.png'),
          wheelRight: require('../assets/image/home/wheel/eu5-02.png'),
          width: '12.706%',
          bottom: '6.133%',
          left: '18.412%',
          right: '18.235%',
          rotateId: [0, 1, 2, 5],
          position: {
            top: '51%',
            left: '51%'
          }
        }
      ], // 车轮
      colorList: [
        {
          id: 14,
          imgUrl:
            '/common/image/2023/05/19/png2_20220726140435A007_20230519151635A078.png',
          vrUrl: null,
          colorImgUrl:
            '/common/image/2023/05/19/bj80 纯灰色_20230519143356A070.png',
          imgVehicleUrl:
            '/common/image/2023/08/16/画板 1 拷贝 3_20230816002606A003.png',
          title: 'BJ80',
          subTitle: 'Galaxy Flagship, Glorious Shinning Trip',
          desc: '<p><span style="color: rgb(32, 32, 32);">Reveal Off-Road Nature, Thorough Guarantee, Military Grade</span></p>',
          sort: 1,
          colors: [
            {
              sort: 1,
              rgbCode: '#060606',
              imgUrl:
                '/common/image/2022/10/28/bj80 黑 修改_20221028110007A013.png'
            },
            {
              sort: 2,
              rgbCode: '#FBFAFA',
              imgUrl:
                '/common/image/2022/10/28/bj80 白 修改_20221028105939A013.png'
            },
            // {
            //   sort: 3,
            //   rgbCode: '#6C6C6C',
            //   imgUrl:
            //     '/common/image/2023/05/19/bj80 纯灰色_20230519143356A070.png'
            // },
            {
              sort: 5,
              rgbCode: '#A11616/#000000',
              imgUrl: '/common/image/2023/05/30/红_20230530141335A097.png'
            },
            {
              sort: 7,
              rgbCode: '#14475F/#FBFAFA',
              imgUrl: '/common/image/2023/05/30/蓝_20230530141414A087.png'
            }
          ]
        },
        {
          id: 31,
          imgUrl:
            '/common/image/2023/10/30/421_20230824163836A007_20231007111940A001_20231030112902A001.png',
          vrUrl: 'https://www.baicglobal.com/vrbj60/',
          colorImgUrl: '/common/image/2023/11/13/005_20231113162655A022.png',
          imgVehicleUrl:
            '/common/image/2023/11/20/BJ60+_20231120162540A033.jpg',
          title: 'BJ60',
          subTitle: 'BJ60',
          desc: null,
          sort: 2,
          colors: [
            {
              sort: 0,
              rgbCode: '#8195A6',
              imgUrl:
                '/common/image/2023/11/24/BJ60_20231115122108A027_20231124094418A015.png'
            }
            // ,
            // {
            //   sort: 1,
            //   rgbCode: '#FFFFFF',
            //   imgUrl: '/common/image/2023/11/13/005_20231113162655A022.png'
            // },
            // {
            //   sort: 2,
            //   rgbCode: '#414E5B',
            //   imgUrl: '/common/image/2024/02/27/bj60-灰_20240227145643A021.png'
            // },
            // {
            //   sort: 3,
            //   rgbCode: '#000000',
            //   imgUrl: '/common/image/2023/11/13/003_20231113162707A024.png'
            // },
            // {
            //   sort: 4,
            //   rgbCode: '#365163',
            //   imgUrl: '/common/image/2023/11/13/002_20231113162714A025.png'
            // },
            // {
            //   sort: 5,
            //   rgbCode: '#566656',
            //   imgUrl: '/common/image/2023/11/13/001_20231113162720A026.png'
            // }
          ]
        },
        {
          id: 20,
          imgUrl: '/common/image/2022/08/17/111223_20220817105902A033.png',
          vrUrl: '',
          colorImgUrl:
            '/common/image/2023/05/19/111111111_20230519152945A075.png',
          imgVehicleUrl:
            '/common/image/2023/08/14/画板 1_20230814235422A045.png',
          title: 'BJ40 PLUS',
          subTitle: 'Well Balance Between City And Wildness',
          desc: '<p>All Terrain Adoption, High Load-Carrying, Part-Time 4WD System</p>',
          sort: 3,
          colors: [
            {
              sort: 0,
              rgbCode: '#BC1D04',
              imgUrl:
                '/common/image/2023/05/19/BJ40-plus-红_20230519153442A082.png'
            }
            ,
            // {
            //   sort: 1,
            //   rgbCode: '#404544',
            //   imgUrl:
            //     '/common/image/2023/05/19/111111111_20230519152945A075.png'
            // },
            {
              sort: 3,
              rgbCode: '#F5F5F5',
              imgUrl:
                '/common/image/2022/10/28/BJ40 plus 白_20221028105002A009.png'
            },
            // {
            //   sort: 4,
            //   rgbCode: '#080808',
            //   imgUrl:
            //     '/common/image/2022/10/28/BJ40 plus 黑_20221028105044A009.png'
            // }
            // ,
            // {
            //   sort: 5,
            //   rgbCode: '#BACBE2',
            //   imgUrl:
            //     '/common/image/2023/05/19/BJ40 plus 海风蓝_20230519142729A063.png'
            // },
            {
              sort: 5,
              rgbCode: '#193346',
              imgUrl:
                '/common/image/2023/05/19/BJ40 plus 拉普兰_20230519152405A074.png'
            }
            // ,
            // {
            //   sort: 6,
            //   rgbCode: '#313D2A',
            //   imgUrl:
            //     '/common/image/2023/05/19/飞书20230519-104944_20230519142740A064.png'
            // },
            // {
            //   sort: 7,
            //   rgbCode: '#5D6043',
            //   imgUrl:
            //     '/common/image/2023/05/19/飞书20230519-104951_20230519142747A065.png'
            // }
          ]
        },
        {
          id: 24,
          imgUrl: '/common/image/2022/08/10/3455_20220810195342A007.png',
          vrUrl: null,
          colorImgUrl:
            '/common/image/2023/03/20/123_20220810195012A013_20230320113739A021.png',
          imgVehicleUrl:
            '/common/image/2023/08/16/画板 1 拷贝_20230816003159A011.png',
          title: 'BJ40 SE',
          subTitle: 'Fun Crossing Your Dream',
          desc: '<p><span style="color: rgb(0, 0, 0);">All-wheel Drive, Three Differential Locks, Two-door Design</span></p>',
          sort: 5,
          colors: [
            {
              sort: 1,
              rgbCode: '#C40F0F',
              imgUrl:
                '/common/image/2023/03/20/bj40-se-红_20230320113748A026.png'
            },
            {
              sort: 2,
              rgbCode: '#030303',
              imgUrl:
                '/common/image/2023/03/20/bj40-se-黑_20230320113723A020.png'
            },
            {
              sort: 3,
              rgbCode: '#F8F8F8',
              imgUrl:
                '/common/image/2023/03/20/bj40-se-白_20230320113731A025.png'
            },
            {
              sort: 4,
              rgbCode: '#404E45',
              imgUrl:
                '/common/image/2023/03/20/123_20220810195012A013_20230320113739A021.png'
            },
            {
              sort: 5,
              rgbCode: '#B9C9E2',
              imgUrl:
                '/common/image/2023/05/19/bj40 se 海风蓝的副本_20230519145911A076.png'
            },
            {
              sort: 6,
              rgbCode: '#193346',
              imgUrl:
                '/common/image/2023/05/19/bj40 se 拉普兰_20230519143048A069.png'
            }
          ]
        },
        {
          id: 25,
          imgUrl:
            '/common/image/2023/03/20/123123123_20220812102220A107_20230320105044A016.png',
          vrUrl: null,
          colorImgUrl: '/common/image/2023/03/20/FF40_20230320110037A018.png',
          imgVehicleUrl: '/common/image/2023/08/16/f40_20230816193713A128.jpg',
          title: 'F40',
          subTitle: 'Wild No Limited',
          desc: '<p><span style="color: rgb(32, 32, 32);">All-new 2.0T Robust Power, A New Generation Of Electronically Controlled Time-shared All-wheel Drive</span></p>',
          sort: 8,
          colors: [
            {
              sort: 0,
              rgbCode: '#BF0808',
              imgUrl: '/common/image/2023/03/20/FF40_20230320110037A018.png'
            },
            {
              sort: 1,
              rgbCode: '#F8F8F8',
              imgUrl:
                '/common/image/2023/05/19/FF40 白色_20230519143450A071.png'
            },
            {
              sort: 2,
              rgbCode: '#BACBE2',
              imgUrl:
                '/common/image/2023/05/19/FF40 海风蓝_20230519143507A067.png'
            },
            {
              sort: 3,
              rgbCode: '#101011',
              imgUrl:
                '/common/image/2023/05/19/FF40 黑色_20230519143521A072.png'
            }
            ,
            // {
            //   sort: 5,
            //   rgbCode: '#193346',
            //   imgUrl:
            //     '/common/image/2023/05/19/FF40 拉普兰_20230519143534A068.png'
            // },
            {
              sort: 6,
              rgbCode: '#55674A',
              imgUrl: '/common/image/2023/05/19/FF40 绿_20230519143603A073.png'
            }
          ]
        },
        {
          id: 17,
          imgUrl: '/common/image/2022/07/26/u5png_20220726101530A003.png',
          vrUrl: '',
          colorImgUrl:
            '/common/image/2022/07/12/u5-plus_20220712151444A056.png',
          imgVehicleUrl: '/common/image/2023/08/16/u5_20230816193728A129.jpg',
          title: 'U5 PLUS',
          subTitle: 'Cheer For Your Life',
          desc: '<p><span style="color: rgb(32, 32, 32);">Excellent Coefficient of Drag Performance, Library-class Quiet Cabin</span></p>',
          sort: 30,
          colors: [
            {
              sort: 0,
              rgbCode: '#CBCBCB',
              imgUrl: '/common/image/2022/07/12/u5-plus_20220712151444A056.png'
            },
            {
              sort: 2,
              rgbCode: '#F0F2F5',
              imgUrl:
                '/common/image/2022/10/28/u5-plus 白_20221028110453A016.png'
            },
            {
              sort: 3,
              rgbCode: '#060606',
              imgUrl:
                '/common/image/2022/10/28/u5-plus 黑_20221028110508A018.png'
            },
            {
              sort: 4,
              rgbCode: '#BB1A1A',
              imgUrl:
                '/common/image/2022/10/28/u5-plus 红 修改_20221028110522A017.png'
            }
            // ,
            // {
            //   sort: 5,
            //   rgbCode: '#485cad',
            //   imgUrl:
            //     'aaaa.png'
            // }
          ]
        },
        {
          id: 8,
          imgUrl: '/common/image/2022/07/26/png4_20220726140420A006.png',
          vrUrl: null,
          colorImgUrl: '/common/image/2022/07/12/eu5_20220712151631A058.png',
          imgVehicleUrl:
            '/common/image/2023/08/16/画板 1 拷贝 5-4_20230816002858A008.png',
          title: 'EU5',
          subTitle: 'Debuting On The Central Role',
          desc: '<p>Strong Power Performance, Intelligence Towards The Future</p>',
          sort: 40,
          colors: [
            {
              sort: 1,
              rgbCode: '#DC1717',
              imgUrl: '/common/image/2022/07/12/eu5_20220712151631A058.png'
            },
            {
              sort: 2,
              rgbCode: '#F9F5F5',
              imgUrl: '/common/image/2022/10/28/eu5 白_20221028110129A015.png'
            },
            {
              sort: 3,
              rgbCode: '#060606',
              imgUrl: '/common/image/2022/10/28/eu5 黑_20221028110143A014.png'
            },
            {
              sort: 4,
              rgbCode: '#5F6163',
              imgUrl: '/common/image/2022/10/28/eu5 灰_20221028110158A016.png'
            },
            {
              sort: 5,
              rgbCode: '#1E41A9',
              imgUrl: '/common/image/2022/10/28/eu5 蓝_20221028110234A017.png'
            },
            {
              sort: 6,
              rgbCode: '#D0D6DD',
              imgUrl: '/common/image/2022/10/28/eu5 银_20221028110253A015.png'
            }
          ]
        },
        {
          id: 30,
          imgUrl:
            '/common/image/2023/09/19/png5_20220817105536A018_20230519151841A079_20230831145326A019_20230919105102A001.png',
          vrUrl: 'https://www.baicglobal.com/vrx7/',
          colorImgUrl:
            '/common/image/2023/11/24/新X7-单色-灰色_20230519150743A072_20231013162808A158_20231024170932A063_20231124094402A014.png',
          imgVehicleUrl:
            '/common/image/2023/09/25/微信图片_20230925161102_20230925180501A218.jpg',
          title: 'ALL NEW X7',
          subTitle: 'ALL NEW X7',
          desc: null,
          sort: 21,
          colors: [
            {
              sort: 0,
              rgbCode: '#6A6A6A',
              imgUrl:
                '/common/image/2023/11/24/新X7-单色-灰色_20230519150743A072_20231013162808A158_20231024170932A063_20231124094402A014.png'
            }
            // ,
            // {
            //   sort: 1,
            //   rgbCode: '#0E0E0F',
            //   imgUrl:
            //     '/common/image/2023/10/13/新X7-单色-黑色_20230519150757A077_20231013162836A159.png'
            // },
            // {
            //   sort: 2,
            //   rgbCode: '#F6F3F3',
            //   imgUrl:
            //     '/common/image/2023/10/13/新X7-单色-白_20230519150728A071_20231013162857A160.png'
            // },
            // {
            //   sort: 4,
            //   rgbCode: '#9AA2A8',
            //   imgUrl:
            //     '/common/image/2023/10/13/新X7-单色-银色_20230519152720A081_20231013162922A161.png'
            // },
            // {
            //   sort: 5,
            //   rgbCode: '#070707/#FFFFFF',
            //   imgUrl:
            //     '/common/image/2023/10/13/新X7-双色-黑+白_20230530143505A098_20231013162948A162.png'
            // },
            // {
            //   sort: 6,
            //   rgbCode: '#010101/#939191',
            //   imgUrl:
            //     '/common/image/2023/10/13/新X7-双色-黑+灰_20230530143514A099_20231013163017A163.png'
            // },
            // {
            //   sort: 7,
            //   rgbCode: '#000000/#E0DEDE',
            //   imgUrl:
            //     '/common/image/2023/10/13/新X7-双色-黑+银_20230530143521A100_20231013163047A164.png'
            // }
          ]
        },
        {
          id: 26,
          imgUrl: '/common/image/2023/01/06/3222_20230106101344A048.png',
          vrUrl: 'https://www.baicglobal.com/x55/?car=X6&lang=en',
          colorImgUrl:
            '/common/image/2023/01/06/111111111_20230106101133A055.png',
          imgVehicleUrl:
            '/common/image/2023/08/16/画板 1 拷贝 5-2_20230816002835A006.png',
          title: 'X55 II',
          subTitle: 'Enjoy Young and Fun ',
          desc: '<p>Vogue Beast Arrival</p>',
          sort: 24,
          colors: [
            {
              sort: 0,
              rgbCode: '#F6F30A',
              imgUrl:
                '/common/image/2023/01/06/111111111_20230106101133A055.png'
            },
            {
              sort: 2,
              rgbCode: '#D21313',
              imgUrl: '/common/image/2022/12/12/3_20221212152213A032.png'
            },
            {
              sort: 3,
              rgbCode: '#29B7A4',
              imgUrl: '/common/image/2022/12/12/5_20221212152250A033.png'
            },
            {
              sort: 4,
              rgbCode: '#424344',
              imgUrl: '/common/image/2022/12/12/1_20221212152312A041.png'
            },
            {
              sort: 5,
              rgbCode: '#B1B3B4',
              imgUrl: '/common/image/2022/12/12/2_20221212152336A034.png'
            },
            {
              sort: 6,
              rgbCode: '#F2F5F7',
              imgUrl: '/common/image/2022/12/12/4_20221212152359A035.png'
            }
          ]
        },
        {
          id: 21,
          imgUrl: '/common/image/2022/07/26/png1_20220726140351A005.png',
          vrUrl: null,
          colorImgUrl:
            '/common/image/2022/07/14/飞书20220714-160804_20220714161844A071.png',
          imgVehicleUrl:
            '/common/image/2023/08/16/画板 1 拷贝 5_20230816002806A004.png',
          title: 'X35',
          subTitle: 'Leading The Fashion Trend',
          desc: '<p>Safe, Enjoyable, High Quality Driving Experience</p>',
          sort: 26,
          colors: [
            {
              sort: 0,
              rgbCode: '#C91D1D',
              imgUrl:
                '/common/image/2022/07/14/飞书20220714-160804_20220714161844A071.png'
            },
            {
              sort: 2,
              rgbCode: '#FEF9F9',
              imgUrl: '/common/image/2022/10/28/x35 白_20221028110631A019.png'
            },
            {
              sort: 3,
              rgbCode: '#1C61D0',
              imgUrl:
                '/common/image/2022/10/28/x35 蓝 修改_20221028110645A018.png'
            },
            {
              sort: 5,
              rgbCode: '#480808',
              imgUrl: '/common/image/2022/10/28/x35 棕_20221028110708A019.png'
            }
          ]
        }
      ]
    }
  },
  mounted() {
    const data = [
      {
        id: 3,
        name: 'Off-road',
        sort: 1,
        vehicleClassSummaries: [
          {
            id: 14,
            imgUrl:
              '/common/image/2023/05/19/png2_20220726140435A007_20230519151635A078.png',
            vrUrl: null,
            colorImgUrl:
              '/common/image/2023/05/19/bj80 纯灰色_20230519143356A070.png',
            imgVehicleUrl:
              '/common/image/2023/08/16/画板 1 拷贝 3_20230816002606A003.png',
            title: 'BJ80',
            subTitle: 'Galaxy Flagship, Glorious Shinning Trip',
            desc: '<p><span style="color: rgb(32, 32, 32);">Reveal Off-Road Nature, Thorough Guarantee, Military Grade</span></p>',
            sort: 1
            // colors: [
            //   {
            //     sort: 1,
            //     rgbCode: '#060606',
            //     imgUrl:
            //       '/common/image/2022/10/28/bj80 黑 修改_20221028110007A013.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#FBFAFA',
            //     imgUrl:
            //       '/common/image/2022/10/28/bj80 白 修改_20221028105939A013.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#6C6C6C',
            //     imgUrl:
            //       '/common/image/2023/05/19/bj80 纯灰色_20230519143356A070.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#A11616/#000000',
            //     imgUrl: '/common/image/2023/05/30/红_20230530141335A097.png'
            //   },
            //   {
            //     sort: 7,
            //     rgbCode: '#14475F/#FBFAFA',
            //     imgUrl: '/common/image/2023/05/30/蓝_20230530141414A087.png'
            //   }
            // ]
          },
          {
            id: 31,
            imgUrl:
              '/common/image/2023/10/30/421_20230824163836A007_20231007111940A001_20231030112902A001.png',
            vrUrl: 'https://www.baicglobal.com/vrbj60/',
            colorImgUrl: '/common/image/2023/11/13/005_20231113162655A022.png',
            imgVehicleUrl:
              '/common/image/2023/11/20/BJ60+_20231120162540A033.jpg',
            title: 'BJ60',
            subTitle: 'BJ60',
            desc: null,
            sort: 2
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#8195A6',
            //     imgUrl:
            //       '/common/image/2023/11/24/BJ60_20231115122108A027_20231124094418A015.png'
            //   },
            //   {
            //     sort: 1,
            //     rgbCode: '#FFFFFF',
            //     imgUrl: '/common/image/2023/11/13/005_20231113162655A022.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#414E5B',
            //     imgUrl:
            //       '/common/image/2024/02/27/bj60-灰_20240227145643A021.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#000000',
            //     imgUrl: '/common/image/2023/11/13/003_20231113162707A024.png'
            //   },
            //   {
            //     sort: 4,
            //     rgbCode: '#365163',
            //     imgUrl: '/common/image/2023/11/13/002_20231113162714A025.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#566656',
            //     imgUrl: '/common/image/2023/11/13/001_20231113162720A026.png'
            //   }
            // ]
          },
          {
            id: 20,
            // imgUrl: '/common/image/2022/08/17/111223_20220817105902A033.png',
            imgUrl: '/common/image/2022/10/28/BJ40%20plus%20白_20221028105002A009.png',
            vrUrl: '',
            colorImgUrl:
              '/common/image/2023/05/19/111111111_20230519152945A075.png',
            imgVehicleUrl:
              '/common/image/2023/08/14/画板 1_20230814235422A045.png',
            title: 'BJ40 PLUS',
            subTitle: 'Well Balance Between City And Wildness',
            desc: '<p>All Terrain Adoption, High Load-Carrying, Part-Time 4WD System</p>',
            sort: 3
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#BC1D04',
            //     imgUrl:
            //       '/common/image/2023/05/19/BJ40-plus-红_20230519153442A082.png'
            //   },
            //   {
            //     sort: 1,
            //     rgbCode: '#404544',
            //     imgUrl:
            //       '/common/image/2023/05/19/111111111_20230519152945A075.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#F5F5F5',
            //     imgUrl:
            //       '/common/image/2022/10/28/BJ40 plus 白_20221028105002A009.png'
            //   },
            //   {
            //     sort: 4,
            //     rgbCode: '#080808',
            //     imgUrl:
            //       '/common/image/2022/10/28/BJ40 plus 黑_20221028105044A009.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#BACBE2',
            //     imgUrl:
            //       '/common/image/2023/05/19/BJ40 plus 海风蓝_20230519142729A063.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#193346',
            //     imgUrl:
            //       '/common/image/2023/05/19/BJ40 plus 拉普兰_20230519152405A074.png'
            //   },
            //   {
            //     sort: 6,
            //     rgbCode: '#313D2A',
            //     imgUrl:
            //       '/common/image/2023/05/19/飞书20230519-104944_20230519142740A064.png'
            //   },
            //   {
            //     sort: 7,
            //     rgbCode: '#5D6043',
            //     imgUrl:
            //       '/common/image/2023/05/19/飞书20230519-104951_20230519142747A065.png'
            //   }
            // ]
          },
          {
            id: 24,
            imgUrl: '/common/image/2022/08/10/3455_20220810195342A007.png',
            vrUrl: null,
            colorImgUrl:
              '/common/image/2023/03/20/123_20220810195012A013_20230320113739A021.png',
            imgVehicleUrl:
              '/common/image/2023/08/16/画板 1 拷贝_20230816003159A011.png',
            title: 'BJ40 SE',
            subTitle: 'Fun Crossing Your Dream',
            desc: '<p><span style="color: rgb(0, 0, 0);">All-wheel Drive, Three Differential Locks, Two-door Design</span></p>',
            sort: 5
            // colors: [
            //   {
            //     sort: 1,
            //     rgbCode: '#C40F0F',
            //     imgUrl:
            //       '/common/image/2023/03/20/bj40-se-红_20230320113748A026.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#030303',
            //     imgUrl:
            //       '/common/image/2023/03/20/bj40-se-黑_20230320113723A020.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#F8F8F8',
            //     imgUrl:
            //       '/common/image/2023/03/20/bj40-se-白_20230320113731A025.png'
            //   },
            //   {
            //     sort: 4,
            //     rgbCode: '#404E45',
            //     imgUrl:
            //       '/common/image/2023/03/20/123_20220810195012A013_20230320113739A021.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#B9C9E2',
            //     imgUrl:
            //       '/common/image/2023/05/19/bj40 se 海风蓝的副本_20230519145911A076.png'
            //   },
            //   {
            //     sort: 6,
            //     rgbCode: '#193346',
            //     imgUrl:
            //       '/common/image/2023/05/19/bj40 se 拉普兰_20230519143048A069.png'
            //   }
            // ]
          },
          {
            id: 25,
            imgUrl:
              '/common/image/2023/03/20/123123123_20220812102220A107_20230320105044A016.png',
            vrUrl: null,
            colorImgUrl: '/common/image/2023/03/20/FF40_20230320110037A018.png',
            imgVehicleUrl:
              '/common/image/2023/08/16/f40_20230816193713A128.jpg',
            title: 'F40',
            subTitle: 'Wild No Limited',
            desc: '<p><span style="color: rgb(32, 32, 32);">All-new 2.0T Robust Power, A New Generation Of Electronically Controlled Time-shared All-wheel Drive</span></p>',
            sort: 8
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#BF0808',
            //     imgUrl: '/common/image/2023/03/20/FF40_20230320110037A018.png'
            //   },
            //   {
            //     sort: 1,
            //     rgbCode: '#F8F8F8',
            //     imgUrl:
            //       '/common/image/2023/05/19/FF40 白色_20230519143450A071.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#BACBE2',
            //     imgUrl:
            //       '/common/image/2023/05/19/FF40 海风蓝_20230519143507A067.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#101011',
            //     imgUrl:
            //       '/common/image/2023/05/19/FF40 黑色_20230519143521A072.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#193346',
            //     imgUrl:
            //       '/common/image/2023/05/19/FF40 拉普兰_20230519143534A068.png'
            //   },
            //   {
            //     sort: 6,
            //     rgbCode: '#55674A',
            //     imgUrl:
            //       '/common/image/2023/05/19/FF40 绿_20230519143603A073.png'
            //   }
            // ]
          }
        ]
      },
      {
        id: 2,
        name: 'SUV',
        sort: 2,
        vehicleClassSummaries: [
          {
            id: 30,
            imgUrl:
              '/common/image/2023/09/19/png5_20220817105536A018_20230519151841A079_20230831145326A019_20230919105102A001.png',
            vrUrl: 'https://www.baicglobal.com/vrx7/',
            colorImgUrl:
              '/common/image/2023/11/24/新X7-单色-灰色_20230519150743A072_20231013162808A158_20231024170932A063_20231124094402A014.png',
            imgVehicleUrl:
              '/common/image/2023/09/25/微信图片_20230925161102_20230925180501A218.jpg',
            title: 'ALL NEW X7',
            subTitle: 'ALL NEW X7',
            desc: null,
            sort: 21
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#6A6A6A',
            //     imgUrl:
            //       '/common/image/2023/11/24/新X7-单色-灰色_20230519150743A072_20231013162808A158_20231024170932A063_20231124094402A014.png'
            //   },
            //   {
            //     sort: 1,
            //     rgbCode: '#0E0E0F',
            //     imgUrl:
            //       '/common/image/2023/10/13/新X7-单色-黑色_20230519150757A077_20231013162836A159.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#F6F3F3',
            //     imgUrl:
            //       '/common/image/2023/10/13/新X7-单色-白_20230519150728A071_20231013162857A160.png'
            //   },
            //   {
            //     sort: 4,
            //     rgbCode: '#9AA2A8',
            //     imgUrl:
            //       '/common/image/2023/10/13/新X7-单色-银色_20230519152720A081_20231013162922A161.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#070707/#FFFFFF',
            //     imgUrl:
            //       '/common/image/2023/10/13/新X7-双色-黑+白_20230530143505A098_20231013162948A162.png'
            //   },
            //   {
            //     sort: 6,
            //     rgbCode: '#010101/#939191',
            //     imgUrl:
            //       '/common/image/2023/10/13/新X7-双色-黑+灰_20230530143514A099_20231013163017A163.png'
            //   },
            //   {
            //     sort: 7,
            //     rgbCode: '#000000/#E0DEDE',
            //     imgUrl:
            //       '/common/image/2023/10/13/新X7-双色-黑+银_20230530143521A100_20231013163047A164.png'
            //   }
            // ]
          },
          {
            id: 26,
            imgUrl: '/common/image/2023/01/06/3222_20230106101344A048.png',
            vrUrl: 'https://www.baicglobal.com/x55/?car=X6&lang=en',
            colorImgUrl:
              '/common/image/2023/01/06/111111111_20230106101133A055.png',
            imgVehicleUrl:
              '/common/image/2023/08/16/画板 1 拷贝 5-2_20230816002835A006.png',
            title: 'X55 II',
            subTitle: 'Enjoy Young and Fun ',
            desc: '<p>Vogue Beast Arrival</p>',
            sort: 24
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#F6F30A',
            //     imgUrl:
            //       '/common/image/2023/01/06/111111111_20230106101133A055.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#D21313',
            //     imgUrl: '/common/image/2022/12/12/3_20221212152213A032.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#29B7A4',
            //     imgUrl: '/common/image/2022/12/12/5_20221212152250A033.png'
            //   },
            //   {
            //     sort: 4,
            //     rgbCode: '#424344',
            //     imgUrl: '/common/image/2022/12/12/1_20221212152312A041.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#B1B3B4',
            //     imgUrl: '/common/image/2022/12/12/2_20221212152336A034.png'
            //   },
            //   {
            //     sort: 6,
            //     rgbCode: '#F2F5F7',
            //     imgUrl: '/common/image/2022/12/12/4_20221212152359A035.png'
            //   }
            // ]
          },
          {
            id: 21,
            imgUrl: '/common/image/2022/07/26/png1_20220726140351A005.png',
            vrUrl: null,
            colorImgUrl:
              '/common/image/2022/07/14/飞书20220714-160804_20220714161844A071.png',
            imgVehicleUrl:
              '/common/image/2023/08/16/画板 1 拷贝 5_20230816002806A004.png',
            title: 'X35',
            subTitle: 'Leading The Fashion Trend',
            desc: '<p>Safe, Enjoyable, High Quality Driving Experience</p>',
            sort: 26
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#C91D1D',
            //     imgUrl:
            //       '/common/image/2022/07/14/飞书20220714-160804_20220714161844A071.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#FEF9F9',
            //     imgUrl:
            //       '/common/image/2022/10/28/x35 白_20221028110631A019.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#1C61D0',
            //     imgUrl:
            //       '/common/image/2022/10/28/x35 蓝 修改_20221028110645A018.png'
            //   },
            //   {
            //     sort: 5,
            //     rgbCode: '#480808',
            //     imgUrl:
            //       '/common/image/2022/10/28/x35 棕_20221028110708A019.png'
            //   }
            // ]
          }
        ]
      },
      {
        id: 6,
        name: 'Sedan',
        sort: 3,
        vehicleClassSummaries: [
          {
            id: 17,
            imgUrl: '/common/image/2022/07/26/u5png_20220726101530A003.png',
            vrUrl: '',
            colorImgUrl:
              '/common/image/2022/07/12/u5-plus_20220712151444A056.png',
            imgVehicleUrl: '/common/image/2023/08/16/u5_20230816193728A129.jpg',
            title: 'U5 PLUS',
            subTitle: 'Cheer For Your Life',
            desc: '<p><span style="color: rgb(32, 32, 32);">Excellent Coefficient of Drag Performance, Library-class Quiet Cabin</span></p>',
            sort: 30
            // colors: [
            //   {
            //     sort: 0,
            //     rgbCode: '#CBCBCB',
            //     imgUrl:
            //       '/common/image/2022/07/12/u5-plus_20220712151444A056.png'
            //   },
            //   {
            //     sort: 2,
            //     rgbCode: '#F0F2F5',
            //     imgUrl:
            //       '/common/image/2022/10/28/u5-plus 白_20221028110453A016.png'
            //   },
            //   {
            //     sort: 3,
            //     rgbCode: '#060606',
            //     imgUrl:
            //       '/common/image/2022/10/28/u5-plus 黑_20221028110508A018.png'
            //   },
            //   {
            //     sort: 4,
            //     rgbCode: '#BB1A1A',
            //     imgUrl:
            //       '/common/image/2022/10/28/u5-plus 红 修改_20221028110522A017.png'
            //   }
            // ]
          }
        ]
      }
      // ,
      // {
      //   id: 5,
      //   name: 'EV',
      //   sort: 4,
      //   vehicleClassSummaries: [
      //     {
      //       id: 8,
      //       imgUrl: '/common/image/2022/07/26/png4_20220726140420A006.png',
      //       vrUrl: null,
      //       colorImgUrl: '/common/image/2022/07/12/eu5_20220712151631A058.png',
      //       imgVehicleUrl:
      //         '/common/image/2023/08/16/画板 1 拷贝 5-4_20230816002858A008.png',
      //       title: 'EU5',
      //       subTitle: 'Debuting On The Central Role',
      //       desc: '<p>Strong Power Performance, Intelligence Towards The Future</p>',
      //       sort: 40
      //       // colors: [
      //       //   {
      //       //     sort: 1,
      //       //     rgbCode: '#DC1717',
      //       //     imgUrl: '/common/image/2022/07/12/eu5_20220712151631A058.png'
      //       //   },
      //       //   {
      //       //     sort: 2,
      //       //     rgbCode: '#F9F5F5',
      //       //     imgUrl:
      //       //       '/common/image/2022/10/28/eu5 白_20221028110129A015.png'
      //       //   },
      //       //   {
      //       //     sort: 3,
      //       //     rgbCode: '#060606',
      //       //     imgUrl:
      //       //       '/common/image/2022/10/28/eu5 黑_20221028110143A014.png'
      //       //   },
      //       //   {
      //       //     sort: 4,
      //       //     rgbCode: '#5F6163',
      //       //     imgUrl:
      //       //       '/common/image/2022/10/28/eu5 灰_20221028110158A016.png'
      //       //   },
      //       //   {
      //       //     sort: 5,
      //       //     rgbCode: '#1E41A9',
      //       //     imgUrl:
      //       //       '/common/image/2022/10/28/eu5 蓝_20221028110234A017.png'
      //       //   },
      //       //   {
      //       //     sort: 6,
      //       //     rgbCode: '#D0D6DD',
      //       //     imgUrl:
      //       //       '/common/image/2022/10/28/eu5 银_20221028110253A015.png'
      //       //   }
      //       // ]
      //     }
      //   ]
      // }
    ]
    const dataMap = new Map()
    this.wheelList.forEach((item) => dataMap.set(item.id, item))
    data.forEach((item) => {
      item.vehicleClassSummaries.forEach((child) => {
        const obj = dataMap.get(child.id)
        if (child.id === obj.id) {
          Object.assign(child, obj, { load: false })
        }
      })
    })
    this.productMenu = data
  },
  methods: {
    // 获取车型图片和颜色
    getProductInfo(id) {
      // console.log(id, 'id');
      const obj = this.colorList.find((obj) => obj.id === id)
      // console.log(obj, 'obj')
      if (obj) {
        const colors = obj.colors
        this.productMenu.forEach((item) => {
          item.vehicleClassSummaries.forEach((child) => {
            if (child.id === id) {
              this.$set(child, 'colors', colors)
            }
          })
        })
        // console.log(this.productMenu, '菜单数据')
      }
    },
    handleLoad(categoryIdx, vehicleIdx) {
      this.productMenu[categoryIdx].vehicleClassSummaries[
        vehicleIdx
      ].load = true
    }
  }
}
</script>

<style lang="scss" scoped>
.index-model-section {
  position: relative;
  background-size: 100% 100%;
}
</style>
