var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.productMenu && _vm.productMenu.length > 0)?_c('div',{staticClass:"models-wrap"},[_c('div',{staticClass:"g-flex-b"},[_c('div',{staticClass:"main"},[_c('ul',{staticClass:"car-tabs"},_vm._l((_vm.productMenu),function(item,index){return _c('li',{key:item.id,staticClass:"el-tabs__item",class:{ active: index === _vm.currentCategory },on:{"click":function($event){return _vm.changeCategory(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"car-content"},[_c('ul',{staticClass:"model-list max-1680",class:{ 'middle-block': _vm.vehicleClass.length === 1 }},_vm._l((_vm.vehicleClass),function(child,vehicleIdx){return _c('li',{key:child.id,staticClass:"model-item",class:{ 'active-car': _vm.currentCar === child.id },style:({ width: 100 / _vm.vehicleClass.length + '%' }),on:{"click":function($event){return _vm.changeCarItem(child, vehicleIdx)}}},[_c('div',{staticClass:"model-thumb-image-wrap"},[_c('img',{attrs:{"src":_vm.baseUrl + child.imgUrl,"alt":""}})]),_c('div',{staticClass:"model-name-wrap"},[(child.title === 'ALL NEW X7')?_c('span',{staticClass:"model-name-text"},[_vm._v("X7")]):(child.title === 'BJ40 PLUS')?_c('span',{staticClass:"model-name-text"},[_vm._v("BJ40 C")]):_c('span',{staticClass:"model-name-text"},[_vm._v(_vm._s(child.title))])])])}),0),_c('swiper',{ref:"carSwiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption},on:{"slideChange":_vm.slideChange,"transitionEnd":_vm.transitionEnd}},_vm._l((_vm.vehicleClass),function(child,vehicleIdx){return _c('swiper-slide',{key:child.id,staticClass:"swiper-slide swiper-no-swiping"},[_c('div',{staticClass:"img-wrap"},_vm._l((child.colors),function(color,colorIdx){return _c('div',{key:colorIdx,staticClass:"model-large-img-item",class:colorIdx === _vm.currentColor
                    ? 'img-item-show'
                    : 'img-item-hide'},[(child.rotateId?.includes(colorIdx))?_c('div',{ref:"carCanvas",refInFor:true,staticClass:"car-canvas-wrap"},[_c('div',{staticClass:"xs"},[_c('img',{staticClass:"rotate-icon",attrs:{"alt":"","src":require("../assets/image/home/360.png")}})]),(
                      child.id === _vm.currentCar && colorIdx === _vm.currentColor
                    )?_c('Car360',{ref:"car360",refInFor:true,attrs:{"images":_vm.generateImages(child, colorIdx),"carWidth":child.carWidth,"position":child.position}}):_vm._e()],1):[_c('el-image',{attrs:{"src":_vm.baseUrl + color.imgUrl},on:{"load":function($event){return _vm.handleLoad(color, colorIdx, vehicleIdx)}}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])]),(child.wheelLeft && child.load && _vm.showWheel)?_c('div',{staticClass:"wheel wheel-left",style:({
                      width: child.width,
                      bottom: child.bottom,
                      left: child.left
                    })},[_c('img',{key:_vm.leftKey,class:{
                        forward: _vm.activeIndex > _vm.prevIndex,
                        backward: _vm.activeIndex < _vm.prevIndex
                      },attrs:{"src":child.wheelLeft,"alt":""}})]):_vm._e(),(child.wheelRight && child.load && _vm.showWheel)?_c('div',{staticClass:"wheel wheel-right",style:({
                      width: child.width,
                      bottom: child.bottom,
                      right: child.right
                    })},[_c('img',{key:_vm.rightKey,class:{
                        forward: _vm.activeIndex > _vm.prevIndex,
                        backward: _vm.activeIndex < _vm.prevIndex
                      },attrs:{"src":child.wheelRight,"alt":""}})]):_vm._e()]],2)}),0)])}),1)],1)]),_c('div',{staticClass:"model-color-wrap"},[_c('el-divider',[(_vm.model)?_c('ul',{staticClass:"color-list"},_vm._l((_vm.model.colors),function(color,colorIdx){return _c('li',{key:colorIdx,staticClass:"color-item",on:{"click":function($event){return _vm.changeColor(colorIdx)}}},[_c('div',{staticClass:"pc"},[_c('pieColor',{staticClass:"color-icon",class:{ active: _vm.currentColor === colorIdx },attrs:{"radius":40,"rgb-code":color.rgbCode && color.rgbCode.split('/')}})],1),_c('div',{staticClass:"xs"},[_c('pieColor',{staticClass:"color-icon",class:{ active: _vm.currentColor === colorIdx },attrs:{"radius":23,"rgb-code":color.rgbCode && color.rgbCode.split('/')}})],1)])}),0):_vm._e()])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }