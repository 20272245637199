import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import '@/assets/css/main.scss'

Vue.config.productionTip = false

Vue.use(Element, {
  locale: enLang // 如果使用中文，无需设置，请删除
})
Vue.use(VueAwesomeSwiper)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
