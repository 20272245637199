import { render, staticRenderFns } from "./pieColor4.vue?vue&type=template&id=2c336800"
import script from "./pieColor4.vue?vue&type=script&lang=js"
export * from "./pieColor4.vue?vue&type=script&lang=js"
import style0 from "./pieColor4.vue?vue&type=style&index=0&id=2c336800&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports